import React from 'react';
import Layout from '../components/layout/Layout';
import { graphql } from "gatsby"; //highlight-line
import Seo from "../components/Seo";
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function BlogPost({ data }) {
  const post = data.allWpPost.nodes[0]
  return (
    <Layout>
      <Seo key={post.id + Math.random()} title={post.seo.title} description={post.seo.metaDesc} />
      <section key={post.id + Math.random()} className="mt-10 max-w-screen-lg mx-auto">
        <div className="mb-4 md:mb-0 w-full mx-auto relative">
          <div className="px-4 lg:px-0">
            <h2 key={post.id + Math.random()} className="text-4xl py-9 font-semibold text-gray-800 leading-tight">
              {post.title}
            </h2>
          </div>
          {post.featuredImage && 
          <GatsbyImage key={post.id + Math.random()} className="object-cover w-full h-96 rounded drop-shadow-lg"
            image={getImage(post.featuredImage.node.localFile)} alt={post.title} />
          }
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-12">
          <div className="px-4 lg:px-0 mt-5 text-gray-700 text-lg leading-relaxed w-full">
            <div key={post.id + Math.random()} className="wp-blog text-justify px-2 lg:text-left text-lg lg:text-1xl mt-0 lg:mt-6 font-light">   {parse(post.content)}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!
    $previousPostId: String
    $nextPostId: String
    ) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        seo{
          metaDesc
          title}
        id
        title
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    previous:  allWpPost(filter: { slug: { eq: $previousPostId  } }) {
      nodes {
        slug
        uri
        id
        title
      }
    }
    next:  allWpPost(filter: { slug: { eq: $nextPostId } }) {
      nodes {
        slug
        uri
        id
        title
      }
    }
  }
`